import {
  Modal,
  useDisclosure,
  VStack,
  Select,
  ModalFooter,
  Button,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import { axiosInstance } from "../../../axios";

import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Input } from "@chakra-ui/react";
import { InputImage } from "../../flux_v2/components/InputImage";
import { Labeled } from "../../../components/molecules/Labeled";
import { slugfy } from "../../../utils";
import { useLocation } from "react-router-dom";
import { appendFormData } from "../../../resources/flux_v2/components/api";

const benefits_economizar = [
  {
    title: "E-commerce",
    image: "/assets/benefits/ecommerce.gif",
    category: "Economizar",
    url: "ecommerce",
  },
  {
    title: "Lojas Físicas",
    image: "/assets/benefits/lojas.gif",
    category: "Economizar",
    url: "local",
  },
  {
    title: "Cinema",
    image: "/assets/benefits/cinema.gif",
    category: "Economizar",
    url: "cinema",
  },
];

const benefits_para_ler = [
  {
    title: "Livros",
    image: "/assets/benefits/livros.gif",
    category: "Benefícios Para Ler",
  },
  {
    title: "Audio Books",
    image: "/assets/benefits/audiobooks.gif",
    category: "Benefícios Para Ler",
  },
  {
    title: "Revista",
    image: "/assets/benefits/revista.gif",
    category: "Benefícios Para Ler",
  },
];

const benefits_beneficios = [
  {
    title: "Telemedicina",
    image: "/assets/benefits/telemedicina.gif",
    category: "Benefícios",
  },
  {
    title: "Music",
    image: "/assets/benefits/music.png",
    category: "Benefícios",
  },
  {
    title: "Ligga Play",
    image: "/assets/benefits/liggaplay.gif",
    category: "Benefícios",
  },
  {
    title: "Cashback",
    image: "/assets/benefits/cashback.gif",
    category: "Benefícios",
  },
  {
    title: "Seguros",
    image: "/assets/benefits/seguros.gif",
    category: "Benefícios",
  },
  {
    title: "Points",
    image: "/assets/benefits/points.png",
    category: "Benefícios",
  },
];

const allBenefits = [
  ...benefits_economizar,
  ...benefits_para_ler,
  ...benefits_beneficios,
];

const ModalCreate = ({ reload }, ref) => {
  const modal = useDisclosure();

  const [valueImage, setvalueImage] = useState();
  const aggrement = useLocation().pathname.split("/")[2];

  const [isLoading, setIsLoading] = useState(false);

  const { getValues, reset, register, setValue, watch, handleSubmit } =
    useForm();

  const title = watch("title");
  const image = watch("icon");
  const predefined = watch("predefined");

  const section_title = watch("section_title");

  const onSubmit = async (formDataJson) => {
    try {
      setIsLoading(true);

      formDataJson.agreement = aggrement;

      if (typeof formDataJson.icon === "string") {
        delete formDataJson.icon;
      }

      const formData = new FormData();

      appendFormData(formData, formDataJson);

      console.log(`formData`, formData);
      if (data?.id) {
        const { data: response } = await axiosInstance.patch(
          `/api/v1/agreement_modules_admin/${data.id}/`,
          formData
        );
        modal.onClose();
        reload();

        return;
      }

      const { data: response } = await axiosInstance.post(
        "/api/v1/agreement_modules_admin/",
        formData
      );
      reload();
      modal.onClose();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const [data, setData] = useState();

  useImperativeHandle(ref, () => ({
    open(data) {
      if (data) {
        setData(data);
        setValue("section_title", data.section_title);
        setValue("title", data.title);
        setValue("icon", data.icon);
        setValue("url", data.url);
        setValue("is_external", data.is_external);
        setvalueImage(null);
      } else {
        setData(null);
        setvalueImage(null);
        reset();
      }
      modal.onOpen();
    },
  }));

  const handleSetValues = async (findCategory) => {
    setValue("section_title", findCategory.category);
    setValue("title", findCategory.title);
    if(findCategory.url){
      setValue("url", findCategory.url);
    } else {
      setValue("url", "");
    }
    setvalueImage(findCategory.image);
  };

  useEffect(() => {
    if (predefined) {
      const findCategory = allBenefits.find(
        (benefit) => benefit.title === predefined
      );

      if (findCategory) {
        handleSetValues(findCategory);
      }
    }
  }, [predefined]);

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{!data ? "Novo Modulo" : "Editar Modulo"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={4}>
              <Labeled label={"Selecione um template (Opcional)"}>
                <Select placeholder="Pré definido" {...register("predefined")}>
                  {allBenefits.map((benefit) => (
                    <option key={benefit.title} value={benefit.title}>
                      {benefit.title}
                    </option>
                  ))}
                </Select>
              </Labeled>
              <Labeled label={"Selecione uma categoria"}>
                <Select
                  placeholder="Selecione uma categoria"
                  {...register("section_title", {
                    required: true,
                  })}
                >
                  <option value="Economizar">Economizar</option>
                  <option value="Benefícios Para Ler">
                    Benefícios Para Ler
                  </option>
                  <option value="Benefícios">Benefícios</option>
                </Select>
              </Labeled>
              <Labeled label={"Nome"}>
                <Input
                  {...register("title", {
                    required: true,
                  })}
                />
              </Labeled>
              <Labeled>
                <InputImage
                  valueImage={valueImage}
                  value={image}
                  onUpload={(url) => {
                    console.log(`url`, url);
                    setValue("icon", url);
                  }}
                  imageName={`${slugfy(title)}-icon`}
                />
              </Labeled>
              <Labeled label={section_title === "Economizar" ? "Ação" : "Url"}>
                <Input
                  disabled={section_title === "Economizar"}
                  {...register("url", {
                    required: true,
                  })}
                />
              </Labeled>
              {section_title !== "Economizar" && (
                <Labeled label={""}>
                  <Checkbox {...register("is_external")} size="md">
                    <Text textTransform={"none"}>Link Externo?</Text>
                  </Checkbox>
                </Labeled>
              )}
            </VStack>
            <ModalFooter>
              <Button isLoading={isLoading} type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(ModalCreate);
